<template>
  <div class="extraInfo">
    <p>创建人：{{creator}}</p>
    <p>修改人：{{modifier}}</p>
    <p>更新时间：{{updateTime}}</p>
    <p>收录时间：{{createTime}}</p>
  </div>
</template>

<script>
export default {
  props: {
    creator: {
      type: String,
    },
    modifier: {
      type: String,
    },
    updateTime: {
      type: String,
    },
    createTime: {
      type: String,
    },
  },
}
</script>

<style lang="less" scoped>
.extraInfo {
  color: #7c7f8e;
  font-size: 12px;
  padding: 15px;
  p {
    line-height: 18px;
  }
}
</style>
